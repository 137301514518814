import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-Black.woff2\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-sans/UntitledSansWeb-BlackItalic.woff2\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-family-sans\",\"display\":\"swap\"}],\"variableName\":\"untitledSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/untitled-serif/UntitledSerifWeb-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"}],\"variable\":\"--font-family-serif\",\"display\":\"swap\"}],\"variableName\":\"untitledSerif\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["PageContextProvider"] */ "/vercel/path0/src/app/components/pageContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PopupProvider"] */ "/vercel/path0/src/app/components/popupProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/app/components/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RailPostHogProvider"] */ "/vercel/path0/src/app/providers/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/providers/postHogPageView.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.scss");
